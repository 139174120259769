@import '../../../styles/var';

.wrap {
    position: relative;

    .title {
        text-align: center;
    }

    .subtitle {
        text-align: center;
        margin-top: 15px;
    }

    .awards {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 100px;
        margin-top: 65px;

        @media(max-width: $md3+px) {
            gap: 30px 60px;
        }

        @media(max-width: $md4+px) {
            margin-top: 50px;
        }

        @media(max-width: $md6+px) {
            gap: 30px 40px;
            margin-top: 30px;
        }

        .award {
            height: 50px;

            @media(max-width: $md3+px) {
                height: 35px;
            }

            @media(max-width: $md6+px) {
                height: 25px;
            }
        }
    }
}