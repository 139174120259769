@import '../../../styles/var';

.container {
    position: relative;
    background: #1F1D1D;
}

.wrap {
    position: relative;
    padding-top: 210px;
    height: 100vh;
    min-height: 650px;
    max-height: 1000px;

    @media(max-width: $md1+px) {
        padding-top: 140px;
        min-height: 750px;
        max-height: 900px;
    }

    @media(max-width: $md3+px) {
        height: auto;
        min-height: unset;
        max-height: unset;
        padding-bottom: 140px;
    }

    @media(max-width: $md4+px) {
        padding-bottom: 0;
    }

    @media(max-width: $md6+px) {
        padding-top: 110px;
    }

    .content {
        position: relative;
        z-index: 5;

        @media(max-width: $md5+px) {
            text-align: center;
        }

        .title {
            color: #fff;
            max-width: 800px;
            line-height: 110%;

            @media(max-width: $md4+px) {
                max-width: 100%;
            }

            @media(max-width: $md5+px) {
                font-size: 40px;
            }

            @media(max-width: $md6+px) {
                font-size: 28px;
            }
        }

        .subtitle {
            max-width: 600px;

            @media(max-width: $md4+px) {
                margin-top: 10px;
            }

            @media(max-width: $md5+px) {
                max-width: 100%;
            }
        }

        .text {
            max-width: 525px;
            margin-top: 15px;

            @media(max-width: $md5+px) {
                margin: 15px auto 0;
            }
        }

        .btn {}
    }
}

.image {
    position: absolute;
    z-index: 3;
    right: 0;
    bottom: 0;
    width: 760px;

    @media(max-width: $md1+px) {
        width: 710px;
    }

    @media(max-width: $md3+px) {
        width: 520px;
    }

    @media(max-width: $md4+px) {
        position: relative;
        display: flex;
        margin-left: auto;
        margin-top: -100px;
    }

    @media(max-width: $md5+px) {
        margin-top: 0;
        width: 100%;
        max-width: 500px;
    }

    img {
        width: 100%;
    }
}