@import '../../../styles/var';

.container {
    background: #1F1D1D;
}

.wrap {
    position: relative;
    padding-top: 270px;
    height: 100vh;
    min-height: 860px;
    max-height: 1100px;

    @media(max-width: $md1+px) {
        padding-top: 220px;
        min-height: 750px;
        max-height: 950px;
    }

    @media(max-width: $md3+px) {
        padding-top: 140px;
        padding-bottom: 140px;
        height: auto;
        min-height: unset;
        max-height: unset;
    }

    @media(max-width: $md4+px) {
        padding-bottom: 0;
    }

    @media(max-width: $md6+px) {
        padding-top: 110px;
    }

    .content {
        position: relative;
        z-index: 5;

        @media(max-width: $md4+px) {
            text-align: center;
        }

        .title {
            margin: -10px 0 30px;
    
            @media(max-width: $md6+px) {
                margin: 0 0 15px;
            }
        }

        .text {
            max-width: 525px;

            @media(max-width: $md4+px) {
                margin: 0 auto;
            }
        }
    }

    .image {
        position: absolute;
        z-index: 2;
        right: 0;
        bottom: 0;
        width: 494px;

        @media(max-width: $md1+px) {
            width: 420px;
        }

        @media(max-width: $md3+px) {
            width: 400px;
        }

        @media(max-width: $md4+px) {
            position: relative;
            display: flex;
            margin: 45px auto 0;
            max-width: 390px;
            width: 90%;
        }

        @media(max-width: $md6+px) {
            width: 242px;
            margin-top: 35px;
        }

        img {
            width: 100%;
        }
    }
}