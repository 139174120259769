@import '../../../styles/var';

.wrap {

    .buttons {
        display: grid;
        grid-template-columns: repeat(3, 230px);
        gap: 20px;
        justify-content: center;

        @media(max-width: $md4+px) {
            grid-template-columns: repeat(3, 1fr);
            gap: 15px;
        }

        @media(max-width: 600px) {
            // grid-template-columns: repeat(1, 1fr);
            // grid-template-rows: repeat(3, 1fr);
            // gap: 5px;
            gap: 5px;
        }

        .button {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            gap: 20px;
            padding: 5px 30px;
            min-height: 56px;
            background: #1F1D1D;
            color: #fff;
            cursor: pointer;
            transition: all .2s;
            font-weight: 500;

            @media(max-width: $md6+px) {
                padding: 5px 20px;
                min-height: 44px;
            }

            &:hover {
                background: rgba($color: #1F1D1D, $alpha: 0.8);
            }

            &.active {
                background: $yellow;
                color: #000;
            }
        }
    }

    .block {
        margin-top: 60px;

        @media(max-width: $md6+px) {
            margin-top: 40px;
        }

        .standardWrap {
            position: relative;

            @media(max-width: 900px) {
                overflow: scroll;
                overflow-y: hidden;
                width: calc(100% + 80px);
                transform: translateX(-40px);
                padding: 0 40px;
            }

            @media(max-width: 600px) {
                width: calc(100% + 36px);
                transform: translateX(-18px);
                padding: 0 18px;
            }

            .standard {
                color: #FFF;
                font-family: $inter;
                font-size: 14px;
                font-weight: 400;
                line-height: 140%;

                @media(max-width: 900px) {
                    width: 880px;
                }

                @media(max-width: $md6+px) {
                    font-size: 12px;
                    width: 700px;
                }

                .row {

                    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
                    display: grid;
                    grid-template-columns: 320px 1fr 1fr 1fr 1fr 1fr;
                    align-items: center;
                    min-height: 45px;

                    @media(max-width: $md4+px) {
                        grid-template-columns: 240px 1fr 1fr 1fr 1fr 1fr;
                    }

                    @media(max-width: $md6+px) {
                        grid-template-columns: 170px 1fr 1fr 1fr 1fr 1fr;
                    }

                    &:nth-child(1) {
                        background: #1F1D1D;
                    }

                    &:nth-child(1),
                    &:nth-child(2) {
                        .col {
                            color: #F9DB71;
                            text-align: center;
                            font-family: $inter;
                            font-size: 17px;
                            font-weight: 700;
                            line-height: normal;

                            @media(max-width: $md4+px) {
                                font-size: 16px;
                            }
                        }
                    }

                    &:hover {
                        background: #1F1D1D;
                    }

                    .title {
                        padding: 5px 0;

                    }

                    .col {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        padding: 5px 0;

                        &:nth-child(2),
                        &:nth-child(4),
                        &:nth-child(6) {
                            background: #1F1D1D;
                        }
                    }
                }
            }
        }

        .professional {

            .swiperWrap {

                .card {

                    &Title {
                        font-weight: 600;
                        text-align: center;
                        color: $yellow;
                    }

                    &Dep {
                        text-align: center;
                        color: $yellow;
                        margin: 0 0 10px;

                        @media(max-width: $md6+px) {
                            margin-top: 5px;
                        }
                    }

                    &Avl {
                        text-align: center;
                    }

                    &Appl {
                        text-align: center;
                        margin: 10px 0 20px;
                    }

                    &Text {
                        display: flex;
                        flex-direction: column;
                        gap: 15px;

                        @media(max-width: $md6+px) {
                            gap: 10px;
                        }
                    }
                }
            }
        }

        .individual {

            .swiperWrap {

                .card {

                    &Title {
                        font-weight: 600;
                        text-align: center;
                        color: $yellow;
                    }

                    &List {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        margin-top: 20px;
                    }
                }
            }
        }

        .nav {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 20px;

            &Btn {
                width: 88px;
                height: 56px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $yellow;
                border-radius: 70px;
                cursor: pointer;
                border: 1px solid $yellow;
                transition: opacity 0.3s, border-color 0.3s, background 0.3s;

                @media(max-width: $md4+px) {
                    width: 70px;
                    height: 46px;
                }

                &.left {
                    transform: rotate(180deg);
                }

                &.inactive {
                    opacity: 1 !important;
                    border-color: #fff;
                    background: transparent;

                    .arrow path {
                        stroke: #fff;
                    }
                }

                .arrow path {
                    transition: all .3s;
                }

                &:hover {
                    opacity: 0.8;
                }
            }

            &Num {
                text-align: center;
            }
        }

        .swiperWrap {
            width: 100%;
            max-width: 100%;
            margin-top: 30px;

            @media(max-width: $md4+px) {
                margin-top: 25px;
            }

            @media(max-width: $md6+px) {
                margin-top: 15px;
            }

            .card {
                padding: 25px 30px;
                border-radius: 20px;
                height: 100%;
                background: #3E3E3E;
                color: #fff;

                @media(max-width: $md6+px) {
                    padding: 25px 20px;
                }
            }
        }
    }
}

:global(.accountseSwiper) {
    overflow: hidden !important;

    overflow: visible !important;

    @media(max-width: $md6+px) {}
}

:global(.accountsSwiperSlide) {
    height: auto !important;
}