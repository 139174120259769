@import '../../../styles/var';

.wrap {
    position: relative;
    padding-top: 280px;
    padding-bottom: 120px;

    @media(max-width: $md1+px) {
        padding-top: 180px;
        padding-bottom: 60px;
    }

    @media(max-width: $md3+px) {
        padding-top: 140px;
        padding-bottom: 0;
    }

    @media(max-width: $md4+px) {
        text-align: center;
    }

    @media(max-width: $md6+px) {
        padding-top: 110px;
    }

    .content {
        position: relative;
        z-index: 5;

        .subtitle {
            margin: -10px 0 30px;
    
            @media(max-width: $md6+px) {
                margin: 0 0 15px;
            }
        }
    
        .text {
            max-width: 525px;
    
            @media(max-width: $md4+px) {
                margin: 0 auto;
            }
        }
    }


    .image {
        position: absolute;
        z-index: 2;
        right: 0;
        top: 250px;
        width: 547px;
        
        @media(max-width: $md1+px) {
            top: 160px;
        }

        @media(max-width: $md3+px) {
            width: 420px;
            top: 180px;
        }

        @media(max-width: $md4+px) {
            position: relative;
            display: flex;
            margin: 50px auto 0;
            top: unset;
        }

        @media(max-width: $md6+px) {
            width: 320px;
        }

        img {
            width: 100%;
        }
    }
}