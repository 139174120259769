@import '../../../styles/var';

.wrap {
    position: relative;
    overflow: hidden;
    border-radius: 50px;
    background: $yellow;
    padding: 45px 70px 65px;

    @media(max-width: $md4+px) {
        padding: 40px 50px 0;
    }

    @media(max-width: $md6+px) {
        padding: 40px 30px 0;
    }

    .content {
        position: relative;
        z-index: 5;
        color: #000;

        * {
            color: #000;
        }

        .title {
            margin: -10px 0 30px;

            @media(max-width: $md4+px) {
                margin-top: -5px;
            }
            
            @media(max-width: $md6+px) {
                margin-top: 0;
            }
        }

        .text {
            max-width: 383px;

            @media(max-width: $md4+px) {
                max-width: 600px;
            }
        }
    }

    .image {
        position: absolute;
        z-index: 2;
        right: 0;
        bottom: 0;
        width: 662px;

        @media(max-width: $md1+px) {
            width: 600px;
        }

        @media(max-width: $md3+px) {
            width: 450px;
        }

        @media(max-width: $md4+px) {
            width: 400px;
            position: relative;
            display: flex;
            margin: 40px -50px 0 auto;
            width: 100%;
            max-width: 400px;
        }

        @media(max-width: $md6+px) {
            margin: 30px -30px 0 auto;
        }

        img {
            width: 100%;
        }
    }
}