@import '../../../styles/var';

.wrap {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;

    @media(max-width: 900px) {
        display: flex;
        flex-direction: column;
    }
    
    @media(max-width: $md6+px) {
        gap: 30px;
    }

    .block {

        .title {
            margin-bottom: 30px;

            @media(max-width: 900px) {
                margin-bottom: 15px;
            }
        }

        .add {
            display: flex;
            flex-direction: column;
            gap: 30px;

            @media(max-width: 900px) {
                gap: 15px;
            }

            .address {
                &Title {
                    display: flex;
                    align-items: flex-end;
                    gap: 10px;
                    color: $yellow;
                }

                &Text {
                    margin-top: 10px;
                }
            }
        }

        .item {
            color: #FFF;
            font-family: $inter;
            font-size: 17px;
            font-weight: 400;
            line-height: 150%;

            @media(max-width: $md4+px) {
                font-size: 16px;
            }

            @media(max-width: $md6+px) {
                font-size: 14px;
            }
        }

        a:hover {
            color: rgba($color: #fff, $alpha: 0.7)
        }
    }
}