@import '../../../styles/var';

.wrap {
    position: relative;
    background: $yellow;
    border-radius: 60px;
    padding: 60px 70px;

    @media(max-width: $md5+px) {
        padding: 50px 50px;
    }

    @media(max-width: $md5+px) {
        padding: 50px 30px;
    }

    @media(max-width: $md6+px) {
        padding: 40px 30px;
        border-radius: 40px;
    }

    .content {
        position: relative;
        z-index: 5;
        color: #000;

        .head {

            .title {
                color: #000;
                line-height: 100%;
            }

            .subtitle {
                margin: 10px 0 20px;

                @media(max-width: $md6+px) {
                    margin: 5px 0 15px;
                }
            }
        }

        .text_01 {
            max-width: 300px;

            @media(max-width: $md4+px) {
                max-width: 100%;
            }
        }

        .text_02 {
            margin-top: 30px;
            max-width: 382px;

            @media(max-width: $md4+px) {
                max-width: 100%;
            }

            @media(max-width: $md6+px) {
                margin-top: 20px;
            }
        }
    }

    .image {
        position: absolute;
        z-index: 3;
        width: 539px;
        bottom: 0;
        right: 0;
        border-radius: 0 0 54px 0;
        overflow: hidden;

        @media(max-width: $md3+px) {
            width: 450px;
        }

        .img {
            width: 100%;
        }
    }
}