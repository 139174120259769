@import './var';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font-100 {
    color: $yellow;
    font-size: 100px;
    font-weight: 700;
    line-height: 130%;

    @media(max-width: $md1+px) {
        font-size: 80px;
    }

    @media(max-width: $md4+px) {
        font-size: 64px;
    }

    @media(max-width: $md6+px) {
        font-size: 38px;
    }
}

.font-70 {
    color: $yellow;
    font-size: 70px;
    font-weight: 700;
    line-height: 130%;

    @media(max-width: $md1+px) {
        font-size: 64px;
    }

    @media(max-width: $md4+px) {
        font-size: 52px;
    }

    @media(max-width: $md6+px) {
        font-size: 32px;
    }
}

.font-50 {
    font-size: 50px;
    font-weight: 700;
    line-height: 150%;

    @media(max-width: $md4+px) {
        font-size: 32px;
    }

    @media(max-width: $md6+px) {
        font-size: 18px;
    }
}

.font-50-28 {
    font-size: 50px;
    font-weight: 700;
    line-height: 150%;

    @media(max-width: $md4+px) {
        font-size: 28px;
    }

    span {
        color: $yellow;
    }
}

.font-30 {
    font-size: 30px;
    font-weight: 700;
    line-height: 150%;

    @media(max-width: $md4+px) {
        font-size: 24px;
    }

    @media(max-width: $md6+px) {
        font-size: 18px;
    }

    span {
        color: $yellow;
    }
}

.font-25 {
    font-size: 25px;
    font-weight: 700;
    line-height: 150%;

    @media(max-width: $md4+px) {
        font-size: 22px;
    }

    @media(max-width: $md6+px) {
        font-size: 18px;
    }
}

.font-22 {
    font-size: 22px;
    font-weight: 700;
    line-height: 150%;

    @media(max-width: $md4+px) {
        font-size: 20px;
    }

    @media(max-width: $md6+px) {
        font-size: 18px;
    }
}

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font-17 {
    font-size: 17px;
    font-weight: 400;
    line-height: 150%;

    @media(max-width: $md4+px) {
        font-size: 16px;
    }

    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}