@import '../../../styles/var';

.container {
    position: relative;
    background: $yellow;
}

.wrap {
    position: relative;
    padding-top: 270px;
    height: 100vh;
    min-height: 860px;
    max-height: 1100px;

    @media(max-width: $md1+px) {
        padding-top: 220px;
        min-height: 750px;
        max-height: 950px;
    }

    @media(max-width: $md3+px) {
        padding-top: 140px;
        padding-bottom: 140px;
        height: auto;
        min-height: unset;
        max-height: unset;
    }

    @media(max-width: $md4+px) {
        padding-bottom: 0;
    }

    @media(max-width: $md6+px) {
        padding-top: 110px;
    }

    .content {
        position: relative;
        z-index: 5;
        color: #000;

        @media(max-width: $md4+px) {
            text-align: center;
        }

        .title {
            display: flex;
            flex-direction: column;

            @media(max-width: $md4+px) {
                display: block;
                font-size: 32px;
            }

            @media(max-width: $md6+px) {
                font-size: 28px;
            }

            span {
                font-size: 100px;
                font-weight: 700;
                line-height: 120%;
                color: #000;

                @media(max-width: $md1+px) {
                    font-size: 80px;
                }

                @media(max-width: $md4+px) {
                    font-size: 32px;
                }

                @media(max-width: $md6+px) {
                    font-size: 28px;
                }
            }
        }

        .subtitle {
            margin: 14px 0 30px;

            @media(max-width: $md4+px) {
                margin-top: 10px;
            }

            @media(max-width: $md6+px) {
                margin: 5px 0 15px;
            }
        }

        .text {
            max-width: 525px;

            @media(max-width: $md4+px) {
                margin: 0 auto;
            }
        }
    }

    .image {
        position: absolute;
        z-index: 3;
        width: 690px;
        right: -65px;
        bottom: 0;

        @media(max-width: $md1+px) {
            right: 0;
            width: 580px;
        }

        @media(max-width: $md3+px) {
            width: 400px;
        }

        @media(max-width: $md4+px) {
            position: relative;
            display: flex;
            margin: 50px auto 0;
        }

        @media(max-width: $md6+px) {
            margin-top: 40px;
            width: 294px;
        }

        img {
            width: 100%;
        }
    }
}