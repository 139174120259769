@import '../../../styles/var';

.wrap {
    position: relative;
    display: grid;
    grid-template-columns: 314px 1fr;
    gap: 70px;

    @media(max-width: $md4+px) {
        display: flex;
        flex-direction: column;
        gap: 60px;
    }

    .nav {
        display: flex;
        flex-direction: column;
        gap: 80px;

        @media(max-width: $md4+px) {
            flex-direction: column-reverse;
            gap: 160px;
        }

        @media (max-width: $md6+px) {
            gap: 100px;
        }

        .buttons {
         
            .btn {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 20px;
                padding: 5px 30px;
                min-height: 56px;
                background: #1F1D1D;
                color: #fff;
                cursor: pointer;
                transition: all .2s;

                &:hover {
                    background: rgba($color: #1F1D1D, $alpha: 0.8);
                }

                .arrow {
                    width: 24px;
                }

                &.active {
                    background: $yellow;
                    color: #000;

                    .arrow path {
                        stroke: #000;
                    }
                }
            }
        }
    }

    .tab {
        
    }
}