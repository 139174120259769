@import '../../../styles/var';

.wrap {
    position: relative;
    padding-top: 170px;

    @media(max-width: $md1+px) {
        padding-top: 140px;
    }

    @media(max-width: $md6+px) {
        padding-top: 110px;
    }

    .text {
        display: grid;
        grid-template-columns: repeat(2, minmax(300px, 525px));
        gap: 50px;
        justify-content: space-between;
        margin-top: 30px;

        @media(max-width: $md4+px) {
            grid-template-columns: repeat(1, 1fr);
            gap: 15px;
        }
    }
}