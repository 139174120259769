@import '../../../styles/var';

.container {
    position: relative;
    overflow: hidden;

    .chart {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top center;
    }

    .bg {
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        height: 375px;
        width: 100%;
        object-fit: cover;
        object-position: top left;

        @media(max-width: $md1+px) {
            height: 320px;
        }

        @media(max-width: $md3+px) {
            position: relative;
            height: 250px;
            margin-top: 50px;
        }

        @media(max-width: $md4+px) {
            height: 192px;
            margin-top: 150px;
        }

        @media(max-width: 600px) {
            transform: translateX(-22%);
            width: 180%;
        }
    }

    .light {
        position: absolute;
        z-index: 4;
        top: 0;
        right: 0;
        width: 710px;

        @media(max-width: $md3+px) {
            width: 600px;
        }
    }
}

.wrap {
    position: relative;
    padding-top: 170px;
    height: 100vh;
    min-height: 900px;
    max-height: 1100px;

    @media(max-width: $md1+px) {
        padding-top: 140px;
        min-height: 780px;
        max-height: 900px;
    }

    @media(max-width: $md3+px) {
        height: auto;
        min-height: unset;
        max-height: unset;
    }

    @media(max-width: $md6+px) {
        padding-top: 110px;
    }

    .content {
        position: relative;
        z-index: 10;
        text-align: center;

        .subtitle {
            margin: 10px 0 15px;
        }

        .text {
            max-width: 525px;
            margin: 0 auto;
        }
    }

    .item {
        position: absolute;
        z-index: 4;

        &_01 {
            left: -70px;
            top: 280px;
            width: 161px;

            @media(max-width: $md1+px) {
                top: 240px;
            }

            @media(max-width: $md3+px) {
                left: 0;
                width: 140px;
                top: unset;
                bottom: -40px;
            }

            @media(max-width: $md4+px) {
                width: 120px;
                bottom: -100px;
                left: 60px;
            }

            @media(max-width: $md6+px) {
                width: 95px;
                bottom: -130px;
            }
        }

        &_02 {
            left: 160px;
            top: 360px;
            width: 66px;

            @media(max-width: $md1+px) {
                top: 320px;
            }
        }

        &_03 {
            left: 355px;
            top: 500px;
            width: 78px;

            @media(max-width: $md1+px) {
                top: 460px;
            }
        }

        &_04 {
            right: 230px;
            top: 470px;
            width: 101px;

            @media(max-width: $md1+px) {
                top: 430px;
            }
        }

        &_05 {
            right: -35px;
            top: 310px;
            width: 230px;

            @media(max-width: $md1+px) {
                top: 270px;
            }

            @media(max-width: $md3+px) {
                right: 0;
                width: 180px;
                top: unset;
                bottom: -100px;
            }

            @media(max-width: $md4+px) {
                width: 160px;
                right: 20px;
                bottom: -170px;
            }

            @media(max-width: $md6+px) {
                width: 100px;
                right: 30px;
                bottom: -180px;
            }
        }
    }
}