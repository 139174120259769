@import '../../../../styles/var';

.tab {
    position: relative;

    .title {}

    .text {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 15px;

        @media(max-width: $md6+px) {
            margin-top: 15px;
        }
    }

    .image {
        margin-top: 50px;

        @media(max-width: $md4+px) {
            margin-top: 40px;
        }

        @media(max-width: $md6+px) {
            margin-top: 30px;
        }

        .img {
            width: 100%;
        }

        .list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px 40px;
            margin-top: 40px;

            @media(max-width: $md4+px) {
                display: flex;
                flex-direction: column;
            }

            @media(max-width: $md6+px) {
                margin-top: 30px;
                gap: 15px;
            }

            .item {
                display: grid;
                grid-template-columns: 27px 1fr;
                gap: 25px;
                align-items: center;

                @media(max-width: $md6+px) {
                    grid-template-columns: 23px 1fr;
                    gap: 20px;
                }

                .check {
                    width: 100%;
                }
            }
        }
    }

    .blocks {
        display: flex;
        flex-direction: column;
        gap: 80px;
        margin-top: 80px;

        @media(max-width: $md4+px) {
            gap: 60px;
            margin-top: 60px;
        }

        @media(max-width: $md6+px) {
            gap: 30px;
        }

        .block {
            display: flex;
            flex-direction: column;
            gap: 30px;

            @media(max-width: $md6+px) {
                gap: 15px;
            }
        }
    }
}