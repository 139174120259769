@import '../../../styles/var';

.wrap {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, minmax(300px, 525px));
    gap: 50px;
    justify-content: space-between;

    @media(max-width: $md4+px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 160px;
    }

    @media(max-width: $md6+px) {
        gap: 100px;
    }

    .col {
        display: flex;
        flex-direction: column;
        gap: 50px;
        justify-content: space-between;
        
        @media(max-width: $md4+px) {
            gap: 40px;
        }

        .content {
            display: flex;
            flex-direction: column;
            gap: 30px;
        }
    }
}