@import '../../../styles/var';

.wrap {
    position: relative;
    width: 100%;
    background: $yellow;
    color: #000;
    text-align: center;
    padding: 35px 24px;

    @media(max-width: $md4+px) {
        max-width: 500px;
        margin: 0 auto;
        border-radius: 50px;
        padding: 35px 20px;
    }

    .title {
        font-size: 40px;
        font-weight: 700;
        line-height: 150%;

        @media(max-width: $md4+px) {
            font-size: 32px;
        }

        @media(max-width: $md6+px) {
            font-size: 28px;
        }
    }

    .subtitle_01,
    .subtitle_02 {
        font-size: 18px;
        font-weight: 700;
        line-height: 150%;
    }

    .subtitle_01 {
        margin: 0 0 12px;

        @media(max-width: $md4+px) {
            margin-bottom: 10px;
        }
    }

    .subtitle_02 {}

    .text {
        color: #000;
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        margin-top: 30px;

        @media(max-width: $md4+px) {
            margin-top: 15px;
        }
    }
}