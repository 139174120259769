@import '../../../styles/var';

.steps {
    position: relative;
    display: grid;
    grid-template-rows: repeat(6, 1fr);

    .card {
        border-bottom: 1px solid $yellow;
        display: grid;
        align-items: center;
        grid-template-columns: auto auto 1fr;
        gap: 50px;
        padding: 18px 0;
        transition: background 0.2s;

        @media(max-width: 600px) {
            gap: 30px;
        }

        @media(max-width: $md6+px) {
            align-items: flex-start;
            gap: 15px;
            padding: 12px 0;
        }

        * {
            transition: all .1s;
        }

        &:hover {
            color: #000;
            background: $yellow;

            .num {
                color: #000;
            }

            .btn {
                border-color: #000 !important;

                .arrow path {
                    stroke: #000;
                }
            }
        }

        .num {
            color: $yellow;
        }

        .content {
            display: grid;
            grid-template-columns: repeat(2, 300px);
            align-items: center;
            gap: 50px;

            @media(max-width: $md4+px) {
                grid-template-columns: 250px 1fr;
            }

            @media(max-width: 900px) {
                display: flex;
                flex-direction: column;
                align-items: unset;
                gap: 15px;
            }

            .title {}

            .text {
                font-size: 14px;
                font-weight: 400;
                line-height: 150%;
            }
        }

        .btnWrap {
            display: flex;
            justify-content: flex-end;

            .btn {
                border-radius: 60px;
                border: 1px solid #F9DB71;
                cursor: pointer;
                width: 88px;
                height: 56px;
                display: flex;
                align-items: center;
                justify-content: center;

                @media(max-width: 900px) {
                    width: 76px;
                    height: 46px;
                }

                @media(max-width: $md6+px) {
                    width: 50px;
                    height: 30px;
                }

                &:hover {
                    background: #000;

                    .arrow path {
                        stroke: $yellow;
                    }
                }

                .arrow {
                    width: 24px;

                    @media(max-width: 900px) {
                        width: 20px;
                    }

                    @media(max-width: $md6+px) {
                        width: 18px;
                    }
                }
            }
        }
    }
}