@import '../../../styles/var';

.wrap {
    position: relative;

    .title {
        text-align: center;
    }

    .tabs {
        margin-top: 50px;

        @media(max-width: $md6+px) {
            margin-top: 20px;
        }

        .tab {
            border-bottom: 1px solid $yellow;
            padding: 30px 0;
            display: grid;
            grid-template-columns: auto 1fr auto;
            justify-content: space-between;
            align-items: center;
            gap: 130px;

            @media(max-width: $md3+px) {
                gap: 80px;
            }

            @media(max-width: $md4+px) {
                grid-template-columns: repeat(2, auto);
                gap: 20px;
            }

            @media(max-width: $md6+px) {
                gap: 15px;
                padding: 20px 0;
            }

            .tier {
                color: $yellow;
                @media(max-width: $md4+px) {
                    grid-column-start: 1;
                    grid-column-end: 1;
                    grid-row-start: 1;
                    grid-row-end: 1;
                }
            }

            .content {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                justify-items: center;
                gap: 130px;

                @media(max-width: $md3+px) {
                    gap: 60px;
                }

                @media(max-width: $md4+px) {
                    grid-column-start: 1;
                    grid-column-end: 3;
                    justify-items: unset;
                }

                @media(max-width: 600px) {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                }

                @media(max-width: $md6+px) {
                    gap: 15px;
                }

                .col {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    
                    @media(max-width: $md6+px) {
                        gap: 5px;
                    }
                }
            }

            .btn {
                @media(max-width: $md4+px) {
                    grid-column-start: 2;
                    grid-column-end: 2;
                    grid-row-start: 1;
                    grid-row-end: 1;
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }
    }
}