@import '../../../styles/var';

.wrap {
    position: relative;

    .title {
        text-align: center;
    }

    .tabs {
        margin-top: 50px;

        @media(max-width: $md6+px) {
            margin-top: 20px;
        }

        .tab {
            padding: 15px 0 30px;
            border-bottom: 1px solid $yellow;

            @media(max-width: $md6+px) {
                padding: 20px 0;
            }

            &Head {
                display: grid;
                align-items: center;
                grid-template-columns: 100px 1fr;
                
                @media(max-width: $md5+px) {
                    grid-template-columns: 70px 1fr;
                }

                @media(max-width: $md6+px) {
                    grid-template-columns: 50px 1fr;
                }

                .tabNum {
                    color: $yellow;
                }

                .tabTitle {
                    @media(max-width: $md6+px) {
                        font-size: 16px;
                    }
                }
            }

            &Content {
                margin-top: 10px;
                padding-left: 100px;
                
                @media(max-width: $md5+px) {
                    padding-left: 0;
                }
                
                .text {

                }

                .list {
                    margin-top: 15px;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                }
            }
        }
    }
}