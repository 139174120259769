@import '../../../styles/var';

.wrap {
    position: relative;
    display: grid;
    grid-template-columns: 314px 1fr;
    gap: 70px;

    .buttons {

        .btn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            padding: 5px 30px;
            min-height: 56px;
            background: #1F1D1D;
            color: #fff;
            cursor: pointer;
            transition: all .2s;

            &:hover {
                background: rgba($color: #1F1D1D, $alpha: 0.8);
            }

            .arrow {
                width: 24px;
            }

            &.active {
                background: $yellow;
                color: #000;

                .arrow path {
                    stroke: #000;
                }
            }
        }
    }

    .content {

        .title {}

        .card {
            margin-top: 50px;

            .text {
                max-width: 420px;
                margin-top: 30px;

                @media(max-width: $md3+px) {
                    max-width: 525px;
                }
            }
        }
    }
}

.wrapMob {
    .tabs {
        display: flex;
        flex-direction: column;
        margin-top: 50px;

        @media(max-width: $md6+px) {
            margin-top: 30px;
        }

        .tab {
            border-bottom: 1px solid $yellow;
            &.active {
                .tabText {
                    display: block;
                }

                .tabStatus::before {
                    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
                }
            }

            &Header {
                cursor: pointer;
                padding: 20px 0;
                display: grid;
                grid-template-columns: 1fr 18px;
                gap: 40px;
                align-items: center;

                @media(max-width: $md6+px) {
                    grid-template-columns: 1fr 16px;
                    padding: 16px 0;
                }

                .tabTitle {
                    font-weight: 600;
                }

                .tabStatus {
                    position: relative;
                    width: 18px;
                    height: 18px;

                    @media(max-width: $md6+px) {
                        width: 16px;
                        height: 16px;
                    }

                    &::before,
                    &::after {
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        background: $yellow;
                        transition: all .1s;
                    }

                    &::before {
                        width: 2px;
                        height: 100%;
                    }

                    &::after {
                        width: 100%;
                        height: 2px;
                    }
                }
            }

            &Text {
                display: none;
                padding: 0 85px 20px 0;

                @media(max-width: $md6+px) {
                    padding: 0 15px 16px 0;
                }
            }
        }
    }
}