@import '../../../styles/var';

.wrap {
    position: relative;
    display: grid;
    grid-template-columns: 450px 1fr;
    align-items: flex-end;
    gap: 150px;

    @media(max-width: $md3+px) {
        gap: 70px;
    }

    @media(max-width: $md4+px) {
        display: flex;
        flex-direction: column;
        gap: 40px;
        align-items: unset;
    }

    @media(max-width: $md6+px) {
        gap: 30px;
    }

    .content {

        .text {
            font-weight: 700;
            margin-top: 20px;
            max-width: 400px;

            @media(max-width: $md4+px) {
                max-width: 100%;
            }
        }
    }

    .list {
        display: grid;
        grid-template-columns: repeat(2, auto);
        gap: 30px 60px;
        justify-content: space-between;

        @media(max-width: $md3+px) {
            gap: 30px 40px;
        }

        @media(max-width: $md4+px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media(max-width: 600px) {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        .item {
            display: grid;
            grid-template-columns: 41px 1fr;
            align-items: center;
            gap: 16px;

            @media(max-width: $md6+px) {
                grid-template-columns: 32px 1fr;
                gap: 20px;
            }

            .check {
                width: 100%;
            }
        }
    }
}