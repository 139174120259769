@import '../../../styles/var';

.container {
    background: $yellow;
}

.wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    color: #000;
    padding: 10px 0;

    @media(max-width: 900px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 20px 0;
    }

    .card {
        text-align: center;
        border-right: 1px solid #000;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &:nth-last-child(1) {
            border: none;
        }

        &:nth-child(2) {
            @media(max-width: 900px) {
                border: none;
            }
        }
    }
}