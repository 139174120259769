@import '../../../styles/var';

.wrap {
    position: relative;
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 100px;
    justify-content: space-between;
    align-items: center;

    @media(max-width: $md3+px) {
        display: flex;
        justify-content: center;
    }
    
    @media(max-width: 800px) {
        flex-direction: column;
        justify-content: unset;
        align-items: unset;
        gap: 0;
    }

    .content {
        .title {
            color: $yellow;

            .subtitle {
                margin-top: -30px;

                @media(max-width: $md4+px) {
                    margin-top: -20px;
                }

                @media(max-width: $md6+px) {
                    margin-top: -10px;
                }
            }
        }

        .text {
            margin-top: 30px;
            max-width: 310px;

            @media(max-width: 800px) {
                margin-top: 15px;
            }
        }

        .btn {
            @media(max-width: 800px) {
                display: none;
            }
        }
    }

    .list {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media(max-width: 800px) {
            margin-top: 30px;
        }

        .item {
            display: flex;
            gap: 30px;
            
            * {
                transition: color .3s;
            }

            &:hover {
                color: $yellow;
            }
        }
    }

    .image {
        width: 263px;

        img {
            width: 100%;
        }
    }

    .btnMob {
        display: none;

        @media(max-width: 800px) {
            display: block;
        }
    }
}