@import '../../../styles/var';

.container {
    position: relative;

    .image {
        position: absolute;
        z-index: 2;
        right: 0;
        top: 220px;
        width: 699px;
        
        @media(max-width: $md1+px) {
            top: 140px;
            width: 600px;
        }

        @media(max-width: $md3+px) {
            width: 420px;
            top: 180px;
        }

        @media(max-width: $md4+px) {
            position: relative;
            display: flex;
            margin-left: auto;
            width: 87%;
            top: unset;
            margin-top: 50px;
        }

        @media(max-width: $md6+px) {
            width: 89%;
        }

        img {
            width: 100%;
        }
    }
}

.wrap {
    position: relative;
    z-index: 5;
    padding-top: 280px;
    padding-bottom: 120px;

    @media(max-width: $md1+px) {
        padding-top: 180px;
        padding-bottom: 60px;
    }

    @media(max-width: $md3+px) {
        padding-top: 140px;
        padding-bottom: 0;
    }

    @media(max-width: $md4+px) {
        text-align: center;
    }

    @media(max-width: $md6+px) {
        padding-top: 110px;
    }

    .title {

    }

    .subtitle {
        margin: -10px 0 30px;

        @media(max-width: $md6+px) {
            margin: 0 0 15px;
        }
    }

    .text {
        max-width: 525px;

        @media(max-width: $md4+px) {
            margin: 0 auto;
        }
    }
}