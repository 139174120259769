@import '../../../styles/var';

.wrap {
    position: relative;
    text-align: center;

    .title {}

    .text {
        margin: 30px auto 75px;
        max-width: 525px;

        @media(max-width: $md4+px) {
            margin-bottom: 50px;
        }

        @media(max-width: $md6+px) {
            margin: 15px auto 50px;
        }
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(1, 1fr);
        gap: 35px;

        @media(max-width: $md3+px) {
            gap: 15px;
        }

        @media(max-width: $md4+px) {
            display: flex;
            flex-direction: column;
        }

        .card {
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: center;
            justify-content: center;
            text-align: center;
            border-radius: 20px;
            padding: 15px 30px;
            
            &:nth-child(1) {
                background: #3E3E3E;
                color: #fff;
            }
            
            &:nth-child(2) {
                background: #FFF;
                color: #000;
            }
            
            &:nth-child(3) {
                background: #F9DB71;
                color: #000;
            }

            &Title {}

            &Text {}
        }
    }
}