@import '../../../styles/var';

.wrap {
    background: $yellow;
    color: #000;
    border-radius: 50px;
    padding: 70px;
    display: grid;
    grid-template-columns: repeat(2, minmax(300px, 525px));
    gap: 50px;
    align-items: center;

    @media(max-width: $md4+px) {
        display: flex;
        flex-direction: column;
        align-items: unset;
        padding: 50px 70px;
    }

    @media(max-width: $md5+px) {
        padding: 50px;
    }

    @media(max-width: 600px) {
        padding: 30px 20px;
        border-radius: 30px;
        gap: 40px;
    }

    .content {

        .title {}

        .text {
            max-width: 402px;
            margin-top: 30px;

            @media(max-width: $md4+px) {
                max-width: 600px;
            }

            @media(max-width: $md6+px) {
                margin-top: 15px;
            }
        }
    }

    .list {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media(max-width: $md6+px) {
            gap: 15px;
        }

        .item {
            display: grid;
            grid-template-columns: 27px 1fr;
            gap: 25px;
            align-items: center;

            @media(max-width: $md6+px) {
                grid-template-columns: 23px 1fr;
                gap: 20px;
            }

            .check {
                width: 100%;
            }
        }
    }
}